.table-bordered {
  font-size: 12px;
  width: 100%;
}

.add-new-item, .delete-item {
  margin-left: 12px;
}

.company-info .delete-item {
  width: 32px!important;
  height: 32px!important;
  padding: 0!important;
}

.delete-item svg {
  margin: 4px;
}

.company-info .delete-item img {
  background-color: #fff!important;
  width: 32px!important;
  height: 32px!important;
  padding: 0!important;
}

.company-info .position-absolute {
  position: absolute;
}

.del-cell {
  vertical-align: initial;
  padding: 6px;
}

.employee-info .company-info .eachRow td.mobile {
  margin-top: 0px!important;
}

.employee-form-col .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.85);
}

@media (max-width: 430px) {
  .company-info .position-absolute {
    position: relative;
  }
  .employee-info .delete-item svg {
    display: block;
  }
}