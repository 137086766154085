.company-info .sub-text {
  margin: 30px 0px;
  margin-bottom: 0px;
}

.title-text {
  margin-bottom: 20px;
}

.user-form {
  margin-left: 60px!important;
}

.margin-bottom-0 {
  margin-bottom: 0px!important;
}

.ant-select-arrow {
  width: 12px!important;
}

.company-info {
  margin-top: 18px;
  margin-bottom: 18px;
}

.company-info .ant-btn-circle {
  width: 32px!important;
  height: 32px!important;
  min-width: 32px!important;;
}