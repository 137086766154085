.ant-card-body {
  font-size: 18px;
  padding-bottom: 0px !important;
}

.sub-text {
  margin: 30px 0px;
}

.ant-steps {
  margin-top: 8px;
}

.home .title-text {
  font-weight: bold;
  font-size: 20px;
}

.process-steps {
  margin-left: 24px !important;
}

.bottom-text {
  margin-top: 0px;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #000 !important;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  min-height: 60px !important;
}

.ant-steps-small .ant-steps-item-icon {
  width: 32px !important;
  height: 32px !important;
  font-size: 18px !important;
  line-height: 32px !important;
  color: #2d2c21 !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #2d2c21 !important;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  border-color: #2d2c21;
}

.ant-steps-item-title {
  font-size: 18px !important;
}

.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-tail {
  left: 16px !important;
  padding: 24px 0 6px !important;
  top: 8px !important;
}

.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-title {
  line-height: 30px !important;
}

.button-container {
  width: 100%;
  margin-bottom: 12px;
  margin: auto;
  text-align: center;
  justify-content: center;
}

.button-container .btn-start,
.button-container .btn-start:hover,
.button-container .btn-start:active,
.button-container .btn-start:focus {
  background: rgb(255, 128, 0);
  border: none;
  color: rgb(255, 255, 255);
  padding: 9px;
  font-size: 18px;
  height: fit-content;
}

.page-header {
  justify-content: center;
}

.home .ant-card-head-title {
  text-align: center;
  font-size: 2em;
}

.home .sub-text {
  margin-top: 12px;
  margin-bottom: 18px;
}

.home .review .ant-steps-item-content {
  min-height: 40px !important;
}
.home .review {
  margin-top: 0;
  margin-bottom: 0;
}

.button-container a {
  color: #fff !important;
}
.process-steps .ant-steps-item-title {
  padding-right: 0px !important;
}

.process-steps .ant-steps-item-icon {
  margin: 0 8px 0 0 !important;
}

.process-steps
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  padding-left: 0px !important;
}

.process-steps .ant-steps-item-icon .ant-steps-icon {
  top: -1px !important;
}

.process-steps .ant-steps-item-tail::after {
  background-color: #294c7c !important;
}


.page-content .bottom-text {
  margin-right: 24px;
  margin-bottom: 36px;
}

@media (max-width: 430px) {
  .page-content {
    background: rgb(244, 246, 248);
    min-height: calc(100vh - 90px);
    padding: 15px !important;
    padding-top: 10px;
    max-width: 900px;
    margin: auto;
    width: 100%!important;
  }

  .page-content .logo-holder {
    height: 75px;
    width: auto;
  }

  .page-content .logo-holder img {
    height: 50px !important;
    margin-top: 6px;
    width: auto!important;
    margin-left: 6px;
  }

  .page-content .abc-logo-holder {
    height: 80px;
    width: 150px !important;
    margin-left: 0px;
  }
  .page-content .abc-logo-holder img {
    height: 60px !important;
    float: inherit;
  }

  .page-content .home .ant-card-head-title {
    text-align: left;
    font-size: 20px !important;
    padding: 7px 0 !important;
    text-align: left!important;
  }

  .page-content .home .title-text {
    font-weight: bold;
    font-size: 17px !important;
    text-align: left !important;
  }

  .page-content .home .process-steps{
    display: grid !important;
  }

  .page-content .bottom-text {
    margin-right: 24px;
    margin-bottom: 18px;
  }
  .page-content .ant-steps .step-1 .ant-steps-item-container {
    height: 76px;
  }
  
  .page-content .ant-steps .step-2 .ant-steps-item-container {
    height: 76px;;
  }
  
  .page-content .ant-steps .step-2  .ant-steps-item-title {
    margin-top: -30px;
    position: absolute;
  }
}

@media (max-width: 376px) {
  .page-content {
    background: rgb(244, 246, 248);
    min-height: calc(100vh - 90px);
    padding: 15px !important;
    padding-top: 10px;
    max-width: 900px;
    margin: auto;
    width: 100%!important;
  }

  .page-content .logo-holder img {
    margin-left: 0px !important;
    height: 50px !important;
    width: auto!important;
  }

  .page-content .logo-holder {
    height: 50px !important;
  }

  .page-content .abc-logo-holder{
    height: 70px !important;
  }

  .page-content .abc-logo-holder img {
    width: 175px!important;
  }

  .page-content .home .ant-card-head-title {
    text-align: left;
    font-size: 19px !important;
    padding: 7px 0 !important;
    text-align: left!important;
  }

  .page-content .home .title-text {
    font-weight: bold;
    font-size: 17px !important;
    text-align: left !important;
  }
}
