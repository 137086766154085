.page-content {
  background: #f0f2f5;
  min-height: calc(100vh - 90px);
  padding: 30px;
  padding-top: 12px;
  max-width: 900px;
  margin: auto;
  width: 100%;
}

.MuiStepper-root .MuiSvgIcon-root {
  width: 36px;
  height: 36px;
}

.home .MuiStepIcon-root.MuiStepIcon-active, .home .MuiStepIcon-root.MuiStepIcon-completed {
  color: #294c7c;
}

.home .MuiStepLabel-iconContainer {
  padding-right: 0px;
}

.home .MuiStep-horizontal, .home .MuiStepper-horizontal {
  padding: 0px;
}

.home .MuiStepConnector-line {
  border-color: #294c7c!important;
}

.home .Mui-disabled .MuiStepIcon-text {
  font-family: Glacial;
}

.home .MuiStepIcon-text {
  font-family: Glacial;
}

.input-card {
  width: 100%;
  min-height: calc(85vh - 120px);
}

.ant-card-head-title {
  font-size: 1.5em;
  color: #294c7c!important;
}

.question-link {
  font-family: Glacial;
  font-size: 18px;
  color: #1d4c80!important;
  margin: auto;
}

.home .prev-button, .home .next-button {
  background-color: rgb(255, 128, 0)!important;
}

.home .mdl-card {
  width: 100%;
  display: none;
}

.home .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-circle {
  width: 32px;
  height: 32px;
}

.home .ant-steps-item-icon {
  font-family: Glacial!important;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
}

.home .mdl-stepper-title {
  display: none;
}

.home .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left, .home .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right {
  top: 18px;
}

.home .mdl-stepper-horizontal-alternative .mdl-stepper-step {
  padding: 0;
}

.home .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-left {
  margin-right: 16px;
}

.home .mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-circle {
  background: #294c7c!important;
}

.home .step {
  background: #294c7c!important;
}

.home .stepper {
  margin-bottom: 12px;
}
.home .pending {
  background: #ededed!important;
  color: #a1a3a7;
  margin: 0;
}

.home .progress, .home .completed {
  margin: 0
}

.home .number {
  font-family: Glacial!important;
  font-size: 18px;
}

.progress-bar, .progress-bar::after {
  height: 1px!important;
  background: #294c7c!important;
}

.home .progress-bar, .home .progress-bar::after {
  height: 1px!important;
  background: #294c7c!important;
}

.completed {
  background-color: #fff!important;
}

.sa-icon {
  border: 0.25em solid #294c7c!important;
}

.sa-fix {
  width: 1.3125em!important;
  height: 7.625em!important;
}

.check-mark, .sa-icon {
  background: transparent!important;
}
.sa-icon::after, .sa-fix, .sa-icon:before, .sa-icon:after {
  background: #fff!important;
}

.sa-line {
  background-color: #294c7c!important;
}

.home .progress::after {
  background: none;
}


.home .mdl-stepper-horizontal-alternative .mdl-stepper-step.step-done .mdl-stepper-circle:before {  
  font-size: 18px;
  line-height: 32px;
  color: #fff;
}

.home .mdl-stepper-circle span {
  color: #fff;
  font-family: Glacial!important;
  font-size: 18px;
  line-height: 32px;
}

.home .mdl-stepper-step:hover {
  background-color: #fff;
}

.home .mdl-stepper-horizontal-alternative .mdl-stepper-step .mdl-stepper-bar-right {
  margin-left: 16px;
}

.home .next-button:disabled {
  background-color: inherit!important;
  border: 1px solid #d9d9d9;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #294c7c!important
}

.ant-steps-item-title {
  padding-right: 0px!important;
}

.ant-steps-item-icon {
  margin: 0!important;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 0px!important;
}

.ant-steps-item-icon .ant-steps-icon {
  top: -1px!important;
}


.page-content .step {
  width: 2.2em;
  height: 2.2em;
}

@media (max-width: 430px) {
  .page-content .home .desktop-stepper {
    display: none;
  }
  .page-content .home .mdl-card {
    display: block;
    margin: 18px auto;
    margin-top: 0;
  }

  .home .stepper {
    margin-bottom: 24px;
  }
}