.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Glacial';
  src: url("./assets/fonts/GlacialIndifference-Regular.otf");
}

@font-face {
  font-family: 'GlacialBold';
  src: url("./assets/fonts/GlacialIndifference-Bold.otf");
}

body {
  font-family: Glacial;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #294c7c!important;
  font-family: Glacial!important;
}

.ant-btn-primary {
  background-color: #294c7c!important;
  border: none;
}

.ant-steps-item-icon, .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  border-color: #294c7c!important;
}

.ant-input, .ant-form-item-label > label {
  font-family: Glacial!important;
  font-size: 18px!important;
}

.ant-input {
  border: 1px solid #376998!important;
}

.ant-select {
  border: 1px solid #376998!important;
}

.ant-select-item, .ant-select {
  font-family: Glacial!important;
  font-size: 18px!important;
  margin-right: 24px!important;
  width: auto;
}

.main-container {
  font-family: Glacial!important; 
}

.ant-input::-webkit-input-placeholder {
  color:rgba(0,0,0,.85);
}

.ant-input::-moz-placeholder {
  color:rgba(0,0,0,.85);
}

