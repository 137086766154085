.not-found-container {
  width: auto;
  background-color: white;
}

.not-found-screen {
  font-family: GlacialInDifference;
  margin: auto;
  width: 1140px;
  margin-top: 0px;
  margin-bottom: 24px;
  min-height: 40vh;
  background-color: white;
}

.not-found-screen .ant-card-bordered {
  border: none;
}

.not-found-screen .ant-card-bordered .ant-card-body {
  padding: 0px!important;
}

.not-found-screen .margin-bottom-12 {
  margin-bottom: -15px !important;
}

.not-found-screen .ant-card-meta-title {
  font-family: "GlacialInDifferenceBold" !important;
}

.not-found-title {
  font-size: 40px;
  color: rgb(29, 76, 128) !important;
  margin-left: -3px !important;
  font-family: raleway;
  font-weight: 400 !important;
  text-align: left;
  line-height: 0.8!important;
  margin-top: 0px!important;
}

.not-found-screen hr {
  margin-top: 20px;
  margin-bottom: 2px;
}

.not-found-screen h5 {
  text-transform: none;
  font-size: 25px !important;
  margin-bottom: 0px;
}

.not-found-screen .naic-text span {
  font-size: 20px;
  font-family: 'GlacialIndifferenceBold'!important;
}
.not-found-screen p {
  text-align: left;
  font-size: 20px;
  padding: 0.5rem;
  margin-bottom: 0px !important;
  padding-left: 0px;
  padding-right: 0px;
}

.not-found-hyperlinks {
  color: #294c7c;
  font-weight: 600 !important;
}

.not-found-available-careers {
  width: 335px;
  border: 1px solid #294c7c;
  border-radius: 24px;
  height: 84px;
  margin-top: 30px;
  background: #294c7c;
}

.not-found-available-careers h3 {
  margin: 10px !important;
}

.not-found-screen .link {
  text-align: center;
  cursor: pointer;
}

.not-found-foot-note {
  font-size: 16px !important;
  padding: 0 !important;
}

@media (max-width: 480px) {
  .not-found-card {
    width: 100% !important;
  }

  .not-found-title {
    font-size: 30px !important;
    margin-bottom: -5px!important;
  }

  .not-found-screen .margin-bottom-12 {
    margin-bottom: 15px !important;
  }
  .not-found-screen {
    width: 100%;
    padding: 25px !important;
    margin-bottom: 0px !important;
    padding-top: 0px!important;
  }

  .not-found-screen p {
    font-size: 19px !important;
    text-align: left;
    margin-top: 0px;
    line-height: 1.4;
    font-weight: 700;
    line-height: 1.25;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .not-found-card {
    width: 95% !important;
  }

  .not-found-title {
    font-size: 35px !important;
    margin-bottom: 5px !important;
    line-height: 0.5 !important;
  }

  .not-found-screen .margin-bottom-12 {
    margin-bottom: 0px !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .not-found-card {
    width: 85% !important;
  }
  
  .not-found-title {
    font-size: 40px !important;
  }
}

@media (max-width: 560px) {
  .not-found-title {
    line-height: 2 !important;
  }
}
