.report-by-type, .report-by-type .ant-table-wrapper {
  justify-content: end;
  width: 100%;
  font-size: 1px;
}

.report-by-type .ant-table-thead > tr > th {
  font-family: Glacial;
  font-size: 14px;
}

.report-by-type img {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.report-by-type button {
  height: 38px;
  font-size: 15px;
  margin-bottom: 20px;
}