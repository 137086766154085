.ant-card-body {
  font-size: 18px;
  padding-bottom: 0px !important;
}

.sub-text {
  margin: 30px 0px;
}

.ant-steps {
  margin-top: 8px;
}

.result .title-text {
  font-weight: bold;
  font-size: 20px;
}

.process-steps {
  margin-left: 24px !important;
}

.bottom-text {
  margin-top: 0px;
}

.ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #000 !important;
}

.ant-steps-small .ant-steps-item-icon {
  width: 32px !important;
  height: 32px !important;
  font-size: 18px !important;
  line-height: 32px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-family: Glacial!important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  border-color: rgba(0, 0, 0, 0.85);
}

.ant-steps-item-title {
  font-size: 18px !important;
}

.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-tail {
  left: 16px !important;
  padding: 24px 0 6px !important;
  top: 8px !important;
}

.ant-steps-vertical.ant-steps-small
  .ant-steps-item-container
  .ant-steps-item-title {
  line-height: 30px !important;
}

.button-container {
  width: 100%;
  margin-bottom: 12px;
  margin: auto;
  text-align: center;
  justify-content: center;
}

.button-container .btn-start,
.button-container .btn-start:hover,
.button-container .btn-start:active,
.button-container .btn-start:focus {
  background: rgb(255, 128, 0);
  border: none;
  color: rgb(255, 255, 255);
  padding: 9px;
  font-size: 18px;
  height: fit-content;
}

.result {
  margin-bottom: 18px;
  margin-top: 0px;
}

.result .greeting {
  font-family: GlacialBold!important;
}

.result .ant-card-bordered {
  border: none;
}
.page-header {
  justify-content: center;
}

.result .ant-card-head-title {
  text-align: center;
  font-size: 2em;
}

.result .sub-text {
  margin-top: 12px;
  margin-bottom: 18px;
}

.result .review .ant-steps-item-content {
  min-height: 40px !important;
}
.result .review {
  margin-top: 0;
  margin-bottom: 0;
}

.button-container a {
  color: #fff !important;
}
.process-steps .ant-steps-item-title {
  padding-right: 0px !important;
}

.process-steps .ant-steps-item-icon {
  margin: 0 8px 0 0 !important;
}

.process-steps
  .ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  padding-left: 0px !important;
}

.process-steps .ant-steps-item-icon .ant-steps-icon {
  top: -1px !important;
}

.process-steps .ant-steps-item-tail::after {
  background-color: #294c7c !important;
}

.page-content .page-header .desktop {
  display: block;
}

@media (max-width: 430px) {
  .page-content {
    background: rgb(244, 246, 248);
    min-height: calc(100vh - 90px);
    padding: 15px !important;
    padding-top: 10px;
    max-width: 100%;
    margin: auto;
    width: 100%!important;
  }

  .page-content .result .ant-card-head-title {
    text-align: center;
    font-size: 20px !important;
    padding: 7px 0 !important;
  }

  .page-content .result .title-text {
    font-weight: bold;
    font-size: 20px !important;
    text-align: left !important;
    margin-top: 12px !important;
  }

  .page-content .result span {
    font-size: 18px !important;
    text-align: justify !important;
  }

  .page-content .result .sub-text{
    font-size: 18px !important;
    text-align: left !important;
    margin-left: 3px !important;
    margin-top: 12px !important;
    margin-bottom: -5px !important;
  }

  .page-content .result .ant-card-body{
    padding: 5px !important;
  }
  
  .page-content .ant-card-body {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px!important;
  }

  .page-header {
    justify-content: flex-start;
    display: grid!important;
    grid-template-columns: auto auto auto auto;
    grid-gap: 18px;
  }

  .page-content .page-header .desktop {
    display: none;
  }
}

@media (max-width: 376px) {
  .page-content {
    background: rgb(244, 246, 248);
    min-height: calc(100vh - 90px);
    padding: 15px !important;
    padding-top: 10px;
    max-width: 100%;
    margin: auto;
    width: 100%!important;
  }

  .page-content .result .ant-card-head-title {
    text-align: center;
    font-size: 19px !important;
    padding: 7px 0 !important;
  }

  .page-content .result .title-text {
    font-weight: bold;
    font-size: 20px !important;
    text-align: left !important;
  }

  .page-content .result span {
    font-size: 18px !important;
    text-align: left !important;
  }

  .page-content .result .sub-text{
    font-size: 18px !important;
    text-align: left !important;
    margin-left: 0px !important;
    margin-top: 12px !important;
    margin-bottom: -5px !important;
  }
  .page-header {
    justify-content: flex-start;
    display: grid!important;
    grid-template-columns: auto auto auto auto;
    grid-gap: 12px;
  }
}
