
.top-header {
  background: rgb(55, 105, 152)!important;
  font-family: 'GlacialBold';
  padding: 0 12px;
  width: 100%;
  z-index: 1;
}

.logo-holder {
  height: 72px;
  width: 175px;
  margin-right: 0px;
}

.abc-logo-holder {
  height: 72px;
  width:175px;
  margin-left: 32px;
}

.page-header {
  width: ;
}

.logo-holder img {
  display: block;
  margin-top: 10px;
  width: auto;
  height: 48px;
}

.abc-logo-holder img {
  display: block;
  margin-bottom: 3px;
  width: 175px;
  height: 64px;
  float: right;
  object-fit: cover;
}

.top-header .title {
  color: #fff;
  margin-top: 9px!important;
  margin: auto;
  text-align: right;
}