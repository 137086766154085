.upload-per-colleague .value {
  margin-left: 24px;
  margin-right: 24px;
}

.report-by-cd .row-width {
  width: 100%;
  margin-bottom: 0px;
}

.report-by-cd .ant-card-body {
  /* padding-left: 27%; */
}

.report-spinner {
  position: absolute!important;
  top: 50%;
  left: 48%;
}

.upload-per-colleague .report-link {
  text-decoration: underline!important;
  cursor: pointer;
}

.upload-per-colleague .summary-list {
  width: 450px;
  margin-left: 24px;
}

.row {
  display: flex;
  width: 100%;
}

.report-by-cd .column1 {
  flex: 0%;
  padding: 10px;
}

.column2 {
  flex: 30%;
  padding: 10px;
}

.report-by-cd th {
  text-align: left;
  min-width: 200px;
}

.back-button {
  float: left;
  color: #294c7c!important;
  font-weight: normal;
  font-size: 14px;
  margin-top: 10px;
}

@media (max-width: 430px) {
  .upload-per-colleague .summary-list {
    min-width: 300px;
    margin-left: 0px;
    margin-top: 8px;
  }
  .report-by-cd .ant-card-body {
    padding-left: 24px;
  }
}