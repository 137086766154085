.company-info .sub-text {
  margin: 30px 0px;
  margin-bottom: 0px;
}

.title-text {
  margin-bottom: 12px !important;
}

.user-form {
  margin-left: 60px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.ant-select-arrow {
  width: 12px !important;
}

.company-info {
  margin-top: 18px;
  margin-bottom: 0px !important;
}

.company-info .ant-card-body {
  padding-bottom: 0px;
}

.ant-picker-input input::placeholder {
  color: #f0f0f0;
}

.company-info .phoneNumber {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgb(0, 0, 0);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all .3s;
  font-family: Glacial!important;
  opacity: .9;
  font-size: 18px!important;
  border: 1px solid #376998!important;
  outline: 0;
}

.home .ant-input[disabled] {
  color: rgba(0, 0, 0, 0.85);
}

.ant-select-selection-placeholder {
  color: rgba(0, 0, 0, 0.85)!important;
  font-family: Glacial !important;
  opacity: 0.9;
  font-size: 18px !important;
}

.error-message {
  color: #ff4d4f;
  font-size: 16px!important;
}


.company-info .desktop {
  display: flex;
}

.company-info .mobile {
  display: none;
}

.ant-select-arrow {
  color: rgba(0, 0, 0, 0.85)!important;
}

::placeholder {
  color: rgba(0, 0, 0, 0.85)!important;
  font-family: Glacial !important;
  opacity: 0.9;
  font-size: 18px !important;
}

.company-info .form-without-label .mobile-bottom-0 {
  margin: 0px!important;
}

@media (max-width: 430px) {
  .page-content .home .ant-steps-vertical {
    flex-direction: inherit !important;
    margin-left: 35px !important;
  }

  .company-info .user-form {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .page-content .home .company-info .title-text {
    font-size: 20px !important;
    margin-top: 12px!important;
  }

  .company-info {
    margin-top: 0px !important;
  }

  .company-info .margin-bottom-0 {
    margin-top: -30px !important;
  }

  .company-info .company-employee-first-name {
    margin-bottom: 15px !important;
  }

  .company-info .company-owner-last-name {
    margin-top: 12px !important;
  }

  .company-info .owner-name-row{
    display: block !important;
  }

  .company-info .margin-bottom-10 {
    margin-bottom: 10px;
  }

  .company-info .desktop {
    display: none;
  }

  .company-info .mobile {
    display: block;
  }

  .company-info .form-with-label {
    margin-bottom: 8px;
  }

  .company-info .form-without-label {
    margin-bottom: 0px;
  }

  .company-info .form-without-label .ant-form-item-label {
    display: none;
  }

  .company-info .form-without-label .mobile-remove-bottom {
    margin-bottom: 8px!important;
  }
  .company-info .form-without-label .ant-form-item {
    margin-bottom: 17px!important;
  }

}

@media (max-width: 376px) {
  .page-content .home .ant-steps-vertical {
    flex-direction: inherit !important;
    margin-left: 35px !important;
  }

  .company-info .user-form {
    margin-right: 5px !important;
    margin-left: 5px !important;
  }

  .page-content .home .title-text {
    font-size: 19px !important;
  }

  .company-info {
    margin-top: 14px !important;
  }

  .company-info .ant-form-item .ant-form-item-label {
    padding: 0 0 2px;
  }

  .company-info .margin-bottom-8 {
    margin-bottom: 8px!important;
  }

  .home .ant-card-body {
    padding-top: 20px;
  }
}
