.employee-info .sub-text {
  margin: 30px 0px;
  margin-bottom: 0px;
}

.employee-info .title-text {
  margin-bottom: 8px !important;
}

.user-form {
  margin-left: 60px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.ant-select-arrow {
  width: 12px !important;
}

.employee-info {
  margin-top: 18px;
  margin-bottom: 18px;
}

.employee-info th {
  font-size: 18px;
  text-align: left;
  font-weight: 100;
}

.bulk-upload .next-btn {
  bottom: 10px;
  position: absolute;
  right: 10px;
}

.ant-upload-text .underline {
  text-decoration: underline;
  text-underline-offset: 0.1em;
}

.employee-info .bulk-upload {
  margin: 40px;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.home .ant-btn-icon-only.ant-btn-lg {
  width: 32px;
  height: 32px;
  min-width: 32px;
}

.home .ant-btn .anticon {
  font-size: 16px;
}

.ant-upload-list-text-container {
  display: none;
}

.ant-upload-list {
  margin-bottom: 30px !important;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 1!important;
}


.ant-upload-list-item-card-actions-btn span {
  color: #ff4d4f!important;
  display: none !important;
}

.ant-upload-list-text .ant-upload-span {
  font-size: 14px;
  color: #52c41a;
}

.employee-info .bulk-upload .ant-upload {
  margin-top: 0px !important;
}

.bulk-upload span {
  width: 100%;
}

.bulk-upload .ant-upload-list-item-name {
  overflow: inherit;
}

.bulk-upload .ant-steps-item-process .ant-steps-item-icon {
  border-color: none;
}

.company-info .sub-text {
  margin: 30px 0px;
  margin-bottom: 0px;
}

.employee-info .desktop {
  display: inline-block;
}

.title-text {
  margin-bottom: 20px;
}

.user-form {
  margin-left: 60px !important;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.ant-select-arrow {
  width: 12px !important;
}

.employee-info .company-info {
  margin-top: 18px;
  margin-bottom: 32px;
}

.company-info .ant-btn-circle {
  width: 32px !important;
  height: 32px !important;
  min-width: 32px !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #294c7c !important;
}

.employee-info .delete-item svg {
  margin: auto;
  display: block;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #294c7c !important;
}

.employee-info .ant-radio-wrapper {
  font-size: 18px;
}

.employee-info .ant-radio-inner::after {
  background-color: #294c7c !important;
}

.employee-info .ant-radio-checked .ant-radio-inner {
  border-color: #294c7c !important;
}

.employee-info .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #294c7c !important;
}

.user-form .ant-form-item-label > label {
  height: 100%;
}

@media (max-width: 430px) {
  .employee-info .employee-form-col{
    margin-top: 0px !important;
  }

  .employee-info tbody {
    width: 100%;
  }
  
  .page-content .home .title-text {
    font-size: 19.5px !important;
    margin-top: 0px !important;
  }

  .page-content .home .ant-steps-vertical {
    flex-direction: inherit !important;
    margin-left: 0px !important;
  }

  .employee-info .user-form {
    margin-right: 10px !important;
    margin-left: 30px !important;
  }

  .employee-info {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }

  .employee-info .company-info .table-bordered {
    display: flex !important;
  }

  .employee-info .company-info tr {
    display: grid !important;
  }

  .employee-info .company-info tr:first-child {
    margin-bottom: 8px !important;
  }

  .employee-info .company-info th {
    font-size: 16px !important;
    margin-top: 32px !important;
    margin-left: -15px !important;
    margin-right: 10px !important;
  }

  .employee-info .company-info th:first-child {
    font-size: 16px !important;
    margin-top: 25px !important;
    margin-left: -15px !important;
    margin-right: 10px !important;
  }

  .employee-info .company-info th:last-child {
    font-size: 16px !important;
    margin-top: 35px !important;
    margin-left: -15px !important;
    margin-right: 10px !important;
  }

  .employee-info .company-info .eachRow tr {
    display: grid !important;
  }

  .employee-info .company-info .eachRow td {
    width: 100% !important;
    margin-top: 8px !important;
  }

  .employee-info .company-info .eachRow .ant-input {
    margin-top: 5px !important;
    width: 100% !important;
  }

  .employee-info .bulk-upload .ant-upload {
    padding-top: 0;
    margin-top: 0!important;
    padding-bottom: 20px;
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 10px !important;
    font-size: 18px !important;
  }

  .employee-info .bulk-upload .ant-upload img {
    height: 54px !important;
  }

  .employee-info .ant-upload-list-text .ant-upload-list-item-name, .ant-upload-list-picture .ant-upload-list-item-name{
    padding: 0 8px !important;
    font-size: 17px !important;
  }

  .employee-info .ant-upload-list-item-info{
    margin-top: 10px !important;
  }

  .employee-info .employee-info-dragger-file-display-div{
    margin-top: 30px !important;
    margin-bottom: -35px !important;
  }

  .employee-info .table-thead-dekstop{
    display: none !important;
  }

  .employee-info .company-info .eachRow .del-cell .delete-item, .employee-info .company-info .eachRow .del-cell .ant-btn-circle {
    width: 38px!important;
    height: 38px!important;
    min-width: 38px!important;
  }

  .employee-info .mobile {
    display: flex;
    flex-direction: row-reverse;
  }
  .employee-info .desktop {
    display: none;
  }
}

@media (max-width: 376px) {
  .employee-info .employee-form-col{
    margin-top: -20px !important;
  }

  .page-content .home .title-text {
    font-size: 18.5px !important;
  }

  .page-content .home .ant-steps-vertical {
    flex-direction: inherit !important;
    margin-left: 0px !important;
  }

  .employee-info .user-form {
    margin-right: 10px !important;
    margin-left: 30px !important;
  }

  .employee-info {
    margin-top: 15px !important;
  }

  .employee-info .company-info .table-bordered {
    display: flex !important;
    margin-top: 12px !important;
  }

  .employee-info .company-info tr {
    display: grid !important;
  }

  .employee-info .delete-item img {
    width: 32px;
    height: 32px;
  }

  .employee-info .company-info th {
    font-size: 16px !important;
    margin-top: 32px !important;
    margin-left: -15px !important;
    margin-right: 10px !important;
  }

  .employee-info .company-info th:first-child {
    font-size: 16px !important;
    margin-top: 25px !important;
    margin-left: -15px !important;
    margin-right: 10px !important;
  }

  .employee-info .company-info th:last-child {
    font-size: 16px !important;
    margin-top: 35px !important;
    margin-left: -15px !important;
    margin-right: 10px !important;
  }

  .employee-info .company-info .eachRow tr {
    display: grid !important;
  }

  .employee-info .company-info .eachRow td {
    width: 100% !important;
    margin-top: 0px !important;
  }

  .employee-info .company-info .eachRow .ant-input {
    margin-top: 5px !important;
    width: 95% !important;
  }

  .employee-info .bulk-upload .ant-upload {
    margin-top: 20px !important;
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 10px !important;
    font-size: 18px !important;
  }

  .employee-info .bulk-upload .ant-upload img {
    height: 55px !important;
    width: 290px !important;
  }

  .employee-info .ant-upload-list-text .ant-upload-list-item-name, .ant-upload-list-picture .ant-upload-list-item-name{
    padding: 0 8px !important;
    font-size: 17px !important;
  }

  .employee-info .ant-upload-list-item-info{
    margin-top: 10px !important;
  }

  .employee-info .employee-info-dragger-file-display-div{
    margin-top: 30px !important;
    margin-bottom: -35px !important;
  }
}
