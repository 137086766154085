.review .ant-card-head-title {
  font-size: 18px;
  text-align: left;
}

.review .ant-card-extra a {
  font-size: 18px;  
}

.review input {
  border: inherit!important;
}

.review {
  margin-top: 32px;
  margin-bottom: 32px;
}

a {
  color: #294c7c!important;
}

.review .ant-card-body {
  padding: 24px!important;
}

.review .sub-title-text {
  font-family: Glacial!important;
  font-size: 18px!important;
  margin-bottom: 12px!important;
  display: block;
}

.review-page {
  margin-top: 28px!important;
  margin-bottom: 18px!important;
}

.review .desktop {
  display: block;
}

.review .mobile {
  display: none;
}

.review-page .ant-input[disabled] {
  font-family: Glacial!important;
  font-size: 18px!important;
  color: rgba(0,0,0,.85);
}

.review-page input:read-only {
  background-color: #f5f5f5;
}

.review-page th {
  font-size: 18px;
}

.review-page .ant-card-head {
  background: #fafafa;
}

.review-page .ant-table table {
  font-size: 18px;
}

.review-page .employee-info-review-card-mobile{
  display: none !important;
}

.review-page .employee-info-review-card-dekstop{
  display: block !important;
}

.review-page .invalid-row {
  color: red;
}

@media (max-width: 430px) {
  .review .ant-table thead tr{
    display: grid !important;
  }

  .review-page .employee-info-review-card-mobile{
    display: block !important;
  }

  .review-page .employee-info-review-card-dekstop{
    display: none !important;
  }

  .review-page .ant-form-item {
    margin-bottom: 8px
  }

  .review table{
    display: inline-flex !important;
  }

  .review .ant-table tbody tr{
    display: grid !important;
    width: 164px !important;
  }

  .review .ant-table tbody tr td{
    border-bottom: none !important;
    margin-top: 3px !important;
    font-size: 16px !important;
    padding-left: 8px !important;
  }
  .review .mobile {
    display: block;
  }
  .review .desktop {
    display: none;
  }
  .review .ant-card-body {
    padding: 14px!important;
  }
  .review-page {
    margin-top: 14px!important;
  }
}

@media (max-width: 376px) {
  .review .ant-table thead tr{
    display: grid !important;
  }

  .review table{
    display: inline-flex !important;
  }

  .review .ant-table tbody tr{
    display: grid !important;
    width: 164px !important;
  }

  .review .ant-table tbody tr td{
    border-bottom: none !important;
    margin-top: 3px !important;
    font-size: 16px !important;
    padding-left: 8px !important;
  }

  .review .ant-table thead tr th{
    width: 115% !important;
    margin-left: -10px !important;
    padding-left: 7px !important;
  }
  .review .mobile {
    display: block;
  }
  .review .desktop {
    display: none;
  }
}