
.bulk-upload .next-btn {
  bottom: 10px;
  position: absolute;
  right: 10px;
}

.bulk-upload {
  margin: 40px;
}

.ant-upload-list {
  margin-bottom: 30px!important;
}

.ant-upload-list-item-card-actions-btn {
  opacity: 1!important;
  color: #ff4d4f!important;
}

.ant-upload-list-text .ant-upload-span {
  font-size: 18px;
  color: #294c7c!important;
  font-weight: bold;
}

.bulk-upload .ant-upload {
  margin-top: 0px!important;
}

.bulk-upload span {
  width: 100%;
}

.bulk-upload .ant-upload-list-item-name {
  overflow: inherit;
}

.bulk-upload .ant-steps-item-process .ant-steps-item-icon {
  border-color: none;
}